import React from "react"
import PropTypes from "prop-types"

const Hero = ({ summary, description }) => (
  <div className="container py-1 pb-16 lg:pb-16" style={{ textShadow: "0 4px 8px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.08" }}>
    <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-white sm:text-5xl sm:leading-none md:text-6xl">
      Ruald Strydom
      <br />
      <span className="text-3xl text-white">{summary}</span>
    </h2>
    <p className="mt-3 text-base text-white sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-0 md:mt-5 md:text-xl lg:mx-0 ">
      {description}
    </p>
  </div>
)

Hero.propTypes = {
  summary: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

export default Hero
