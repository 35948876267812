import { graphql } from "gatsby"
import React from "react"
import EducationCards from "../components/EducationCards"
import EducationList from "../components/EducationList";
import Hero from "../components/Hero"
import Layout from "../layouts/Layout"
import Newsletter from "../components/Newsletter"
import SiteMetadata from "../components/SiteMetadata"

const EducationPage = ({ data }) => {


  return (
    <Layout>
      <SiteMetadata title="Education" description="Ruald Strydom" />

      <div className="bg-gray-100 py-12 lg:py-16">
        <div className="container py-1 lg:pb-1">
          <h1 className="text-3xl leading-tight font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Education
          </h1>
        </div>
        {data.Education && data.Education.nodes.length > 0 ? (
          <EducationCards items={data.Education.nodes} />
        ) : (
          <div className="container"></div>
        )}
      </div>
    </Layout>
  )
}

export default EducationPage

export const query = graphql`
      query EducationQuery {
        Education: allContentfulEducation(sort: {fields: name, order: ASC }) {
        nodes {
        ...EducationCard
      }
    }
  }
      `
